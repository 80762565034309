var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"440","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.close.apply(null, arguments)}},model:{value:(_vm.displayDialog),callback:function ($$v) {_vm.displayDialog=$$v},expression:"displayDialog"}},[(_vm.step === 'view')?_c('TreeViewer',{attrs:{"loading":!Object.entries(_vm.tree).length,"tree":_vm.tree},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{on:{"click":_vm.close}},[_vm._v(" Stäng ")])],1),_c('v-spacer'),_c('v-col',[_c('v-btn',{attrs:{"disabled":_vm.$store.state.offline},on:{"click":function($event){_vm.step = 'delete'}}},[_vm._v(" Ta bort ")])],1),_c('v-col',[_c('v-btn',{attrs:{"disabled":_vm.$store.state.offline},on:{"click":function($event){_vm.step = 'edit'
              _vm.newTree = { ..._vm.tree }}}},[_vm._v(" Redigera ")])],1)],1)]},proxy:true}],null,false,1740413219)}):_vm._e(),(_vm.step === 'edit')?_c('TreeEditor',{attrs:{"is-editing":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Redigera träd ")]},proxy:true},{key:"buttons",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{on:{"click":function($event){_vm.step = 'view'}}},[_vm._v(" Tillbaka ")])],1),_c('v-spacer'),_c('v-col',[_c('v-btn',{attrs:{"color":"green","disabled":!_vm.newTree.valid || _vm.treesAreEqual(_vm.newTree, _vm.tree)},on:{"click":_vm.towardsPreview}},[_vm._v(" Fortsätt ")])],1)],1)]},proxy:true}],null,false,3490073409),model:{value:(_vm.newTree),callback:function ($$v) {_vm.newTree=$$v},expression:"newTree"}}):_vm._e(),(_vm.step === 'preview')?_c('TreeViewer',{attrs:{"tree":_vm.newTree,"preview":true},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{on:{"click":function($event){_vm.step = 'edit'}}},[_vm._v(" Tillbaka ")])],1),_c('v-col',[_c('v-btn',{attrs:{"color":"green","disabled":_vm.$store.state.offline},on:{"click":_vm.submitTree}},[_vm._v(" Spara ändringar ")])],1),_c('v-spacer'),_c('v-col',[_c('v-btn',{on:{"click":_vm.close}},[_vm._v(" Avbryt ")])],1)],1)]},proxy:true}],null,false,408155281)}):_vm._e(),(_vm.step === 'delete')?_c('v-card',{attrs:{"loading":_vm.working}},[_c('v-card-title',[_vm._v("Anmäl träd för borttagning")]),_c('v-card-text',[_c('p',[_vm._v(" Tala om varför det här trädet ska tas bort, så tar en av oss hand om det så snart som bara möjligt. ")]),_c('v-textarea',{attrs:{"disabled":_vm.working,"rules":[
          v => {
            if (v && v.trim()) {
              return true
            }
            return 'Du behöver tala om varför trädet ska tas bort'
          },
        ],"required":"","label":"Motivering"},model:{value:(_vm.deleteReason),callback:function ($$v) {_vm.deleteReason=$$v},expression:"deleteReason"}})],1),_c('v-card-actions',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{on:{"click":function($event){_vm.step = 'view'}}},[_vm._v(" Tillbaka ")])],1),_c('v-spacer'),_c('v-col',[_c('v-btn',{attrs:{"color":"red","disabled":_vm.working ||
              _vm.$store.state.offline ||
              !_vm.deleteReason ||
              !_vm.deleteReason.trim()},on:{"click":_vm.flagForDeletion}},[_vm._v(" Anmäl för borttagning ")])],1)],1)],1)],1):_vm._e(),_c('ConfirmDialog',{ref:"confirm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }