var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._t("title")],2),_c('v-card-text',[_c('v-form',{on:{"input":function($event){return _vm.$emit('input', _vm.tree_)}},model:{value:(_vm.tree_.valid),callback:function ($$v) {_vm.$set(_vm.tree_, "valid", $$v)},expression:"tree_.valid"}},[_c('v-select',{attrs:{"required":"","rules":[v => !!v || 'Du måste ange en trädsort'],"items":_vm.insertableTrees,"readonly":_vm.isEditing,"disabled":_vm.isEditing,"label":"Trädsort"},model:{value:(_vm.tree_.type),callback:function ($$v) {_vm.$set(_vm.tree_, "type", $$v)},expression:"tree_.type"}}),_c('div',[(_vm.file)?_c('v-img',{attrs:{"src":_vm.previewSource,"height":"194","alt":"Ny bild"}}):_c('TreeImage',{attrs:{"image":_vm.tree.file,"alt":"Nuvarande bild"}})],1),(_vm.tree_.file && !_vm.file)?_c('div',[_c('v-btn',{staticClass:"px-2 mt-1",attrs:{"small":"","color":"red lighten-3"},on:{"click":_vm.deleteImage}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDeleteOutline))]),_vm._v("Ta bort bilden ")],1)],1):_vm._e(),_c('v-file-input',{attrs:{"accept":"image/*","label":"Ladda upp en bild"},on:{"change":_vm.fileChanged},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('v-progress-linear',{attrs:{"active":_vm.uploading,"value":_vm.uploadingProgress,"striped":true}}),(_vm.file)?_c('p',[_vm._v(" Bildens rotation kan vara fel här, men bör bli rätt i nästa steg. ")]):_vm._e(),_c('v-textarea',{attrs:{"rules":[
          v => {
            if (v && v.trim()) {
              return true
            }
            return 'Beskriv trädet innan du fortsätter'
          },
        ],"required":"","label":"Beskrivning"},model:{value:(_vm.tree_.desc),callback:function ($$v) {_vm.$set(_vm.tree_, "desc", $$v)},expression:"tree_.desc"}})],1)],1),_c('v-card-actions',[_vm._t("buttons")],2),_c('ConfirmDialog',{ref:"confirm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }